<template>
  <confirm-modal
    :triggerOpen="triggerOpen"
    :text="$t('ticket_order.confirm.text')"
    :title="$t('ticket_order.confirm.title')"
    :acceptText="$t('ticket_order.confirm.confirm')"
    :cancelText="$t('ticket_order.confirm.cancel')"
    @accept="$emit('accept')"
    @closedAfterAccept="$emit('closedAfterAccept')"
    @closed="$emit('closed')"
    :keepOpenAfterAccept="true"
    :showCloseBtn="completedAction"
  >
    <template v-slot:content v-if="paymentMethodId === cashPaymentMethodId">
      <v-layout row class="mx-4 my-2">
        <v-flex xs6>
          <v-text-field
            :label="$t('fields.cash')"
            outlined
            class="mx-2 currency"
            hide-details
            autofocus
            :value="displayAmount"
            @input="(a) => setAmount(a)"
          >
            <template v-slot:append>
              <span class="grey--text currency">/ {{formatAmount(applyCurrencyExchange(total, currency.id))}} {{currency.name}}</span>
            </template>
          </v-text-field>
        </v-flex>
        <v-flex xs6>
          <v-layout column class="grey--text">
            <div class="subtitle-2">{{$t('fields.change')}}</div>
            <div class="title" :class="{'error--text': (amount - applyCurrencyExchange(total, currency.id)) < 0 }">{{ formatAmount((amount - applyCurrencyExchange(total, currency.id))) }} {{ currency.name }}</div>
          </v-layout>
        </v-flex>
      </v-layout>
      <slot name="errors" />
    </template>
    <template v-slot:content v-else>
      <slot name="errors" />
    </template>
  </confirm-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import moneyFormat from '@/mixins/MoneyFormat'
import pricingHelpers from '@/mixins/PricingHelpers'
export default {
  props: {
    total: Number,
    currency: Object,
    triggerOpen: Date,
    paymentMethodId: String,
    completedAction: Boolean
  },
  data () {
    return {
      displayAmount: null,
      amount: null
    }
  },
  computed: {
    ...mapGetters({
      cashPaymentMethodId: 'configuration/cashPaymentMethodId',
      foreignCurrencyId: 'configuration/foreignCurrencyId'
    })
  },
  methods: {
    setAmount (amount) {
      this.displayAmount = null
      if (typeof amount !== 'undefined' && amount !== '') {
        this.amount = this.parsePrice(amount)
        this.$nextTick(() => {
          this.displayAmount = this.sanitizePrice(amount, this)
        })
      } else {
        this.amount = null
        this.$nextTick(() => {
          this.displayAmount = null
        })
      }
    }
  },
  mixins: [
    moneyFormat,
    pricingHelpers
  ],
  components: {
    ConfirmModal: () => import('@/components/app-components/modals/ConfirmModal.vue')
  }
}
</script>